import classNames from 'classnames';
import React from 'react';
import formatFirstPubDate from '../../../utils/formatFirstPubDate';
import {TopicArticle} from '../../App/App';
import ShareBarDesktop, {ShareBarDesktopProps} from '../ShareBarDesktop/ShareBarDesktop';

import styles from './styles.css';

interface ArticleProps {
  article: TopicArticle;
  articleLinkHostName: string;
}

const Article: React.FC<ArticleProps> = ({
  article,
  article: {articleURL, firstPubDate, headline, images, previewText, sponsoredArticle, isPaywalled},
  articleLinkHostName
}) => {
  const link = `${articleLinkHostName}${articleURL}`;

  const defaultImg = images.find(({imageType}) => imageType === 'ARTICLE_PREVIEW_THUMB');

  const shareBarProps: ShareBarDesktopProps = {
    article
  };

  return (
    <li className={styles.article}>
      <a className={styles.leftCol} href={link}>
        <img src={defaultImg?.hostUrl} alt={defaultImg?.altTag || undefined} />
      </a>

      <div className={classNames(styles.rightCol, 'article')}>
        <h2 className="linkro-darkred">
          {isPaywalled && <span className="is-paywalled" />}
          <a href={link}>{headline}</a>
        </h2>

        {firstPubDate && <div className={styles.firstPubDate}>{formatFirstPubDate(firstPubDate)}</div>}

        <div className={classNames(styles.previewText, 'link-ccow')}>
          {sponsoredArticle && <span className={styles.sponsored}>Sponsored</span>}
          {previewText}
        </div>

        <ShareBarDesktop {...shareBarProps} />
      </div>
    </li>
  );
};

export default Article;

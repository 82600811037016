import classNames from 'classnames';
import React from 'react';
import {TopicArticle} from '../../App/App';
import ShareBarMobile from '../ShareBarMobile/ShareBarMobile';
import styles from './styles.css';

interface ArticleProps {
  article: TopicArticle;
  articleLinkHostName: string;
}

const Article: React.FC<ArticleProps> = ({
  article,
  article: {
    articleURL,
    headline,
    articleId,
    images,
    mobileGalleryCount,
    previewText,
    socialHeadline,
    sponsoredArticle,
    isPaywalled
  },
  articleLinkHostName
}) => {
  const link = `${articleLinkHostName}${articleURL}`;
  const img = images.find(({imageType}) => imageType === 'ARTICLE_PREVIEW_LARGE');

  return (
    <li className={classNames(styles.article, 'article-preview')} data-id={articleId} data-title={socialHeadline}>
      <a href={link}>
        <h2>
          {isPaywalled && <span className="is-paywalled" />}
          {headline}
        </h2>
        <div className={styles.imgWrapper}>
          <div className={styles.imgIconWrapper}>
            <div className={styles.imgIcon} />
            <span>+{mobileGalleryCount}</span>
          </div>
          <img src={img?.hostUrl} alt={img?.altTag || undefined} />
        </div>
        <div className="article-preview-description">
          <p>
            {sponsoredArticle && <span className={styles.sponsored}>Sponsored</span>}
            {previewText}
          </p>
        </div>
      </a>

      <ShareBarMobile article={article} />
    </li>
  );
};

export default Article;

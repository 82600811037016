import classNames from 'classnames';
import React from 'react';
import {AppPropsAndState} from '../../App/App';
import InfiniteScroll from '../../InfiniteScroll/InfiniteScroll';
import Articles from '../Articles/Articles';
import Title from '../Title/Title';
import Pagination from '../Pagination/Pagination';

import styles from './styles.css';

const MobileApp: React.FC<AppPropsAndState> = (props) => {
  return (
    <div className={classNames(styles.mobileApp, props.isPlatformCc ? styles.disableAllPointerEvents : '')}>
      {props.showMobileTitle && <Title channel={props.channel} topic={props.topic} />}

      {props.isPaginated && <Pagination {...props} />}

      <div data-track-module="topics_pagination_mobile">
        <Articles articles={props.topicArticles} articleLinkHostName={props.articleLinkHostName} />

        {props.isInfiniteScroll && <InfiniteScroll {...props} layout="mobile" />}
      </div>

      {props.isPaginated && <Pagination {...props} />}
    </div>
  );
};

export default MobileApp;

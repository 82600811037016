import React, {useState} from 'react';

import DesktopApp from '../desktop/App/App';
import MobileApp from '../mobile/App/App';
import StructuredData from '../StructuredData/StructuredData';

export interface TopicArticleChannel {
  channelId: number;
  h1DisplayName: string;
  topics: {
    previewText: string;
    topicName: string;
  }[];
}

export interface TopicArticle {
  articleId: number;
  articleURL: string;
  authors: string[];
  channel: string;
  commentsCount: number;
  fbShareCount?: number;
  firstPubDate: null | string;
  headline: string;
  images: {altTag: null | string; hostUrl: string; imageType: string}[];
  isPaywalled?: boolean | null;
  largeHeadline: string;
  largePreviewText: string;
  location: string | null;
  mobileGalleryCount: number;
  modifiedDate: string;
  pageTitle: string;
  piShareCount?: number;
  previewText: string;
  socialHeadline: string;
  sponsoredArticle: null | boolean;
  videoCount: number;
}

export interface AppProps {
  articleLinkHostName: string;
  channel: TopicArticleChannel;
  clientSideMolFeFeedsUrl: string;
  envUrl: string;
  isInfiniteScroll: boolean;
  isMobile: boolean;
  isPaginated: boolean;
  isPlatformCc: boolean;
  page: number;
  paywalledArticlesAPIUrl: string; // for debugging purposes
  serverGeo: string;
  serverSideMolFeFeedsUrl: string;
  showMobileTitle: boolean;
  size: number;
  skipArticleId: null | string;
  topic: string;
  topicArticles: TopicArticle[];
  totalPages: number;
}

export interface AppState {
  curPage: number;
  setCurPage: React.Dispatch<React.SetStateAction<number>>;
  setTopicArticles: React.Dispatch<React.SetStateAction<TopicArticle[]>>;
  topicArticles: TopicArticle[];
}

export type AppPropsAndState = AppProps & AppState;

const App: React.FC<AppProps> = (props) => {
  const {articleLinkHostName, isMobile, page} = props;

  const [curPage, setCurPage] = useState(Number(page));
  const [topicArticles, setTopicArticles] = useState<TopicArticle[]>(props.topicArticles);

  const appStateProps: AppState = {
    curPage,
    setCurPage,
    setTopicArticles,
    topicArticles
  };

  return (
    <>
      <StructuredData articleLinkHostName={articleLinkHostName} topicArticles={topicArticles} />
      {isMobile ? <MobileApp {...props} {...appStateProps} /> : <DesktopApp {...props} {...appStateProps} />}
    </>
  );
};

export default App;
